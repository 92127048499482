.root {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: max-content;
  max-width: 1250px;

  .buttonBox {
    display: flex;
    gap: 1em;

    .button {
      text-transform: uppercase;
    }
  }
}
