.container {
  display: flex;
  flex-direction: column;
  max-width: 1250px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .sessionContainer {
    display: flex;
    gap: 0.75rem;
    flex-wrap: wrap;
    justify-content: space-between;

    .sessionCard {
      min-width: 300px;
      width: calc(50% - 0.5rem);
      display: flex;
      flex: 1 0 calc(50% - 0.75rem);
      flex-direction: column;

      .cardActions {
        margin-top: auto;
        justify-content: flex-end;
        align-items: flex-end;

        .link {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
