.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  height: 100%;
  justify-content: center;

  .columnContainer {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
  }

  .column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    flex: 1 0 calc(50% - 0.75rem);
    min-width: 400px;
  }

  .row {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;

    .formWrapper {
      flex: 1 0 calc(30% - 0.5rem);
      min-width: 200px;
    }
  }

  .description {
    height: 100%;
  }
}
