.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2em;

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
}
