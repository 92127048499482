.wrapper {
  display: flex;
  gap: 1em;

  .linkWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    max-width: 10vw;
    gap: 0.5em;
    text-transform: uppercase;
  }
}
