.container {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  max-width: 1500px;
  margin: 0 auto;

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 calc(25% - 0.75rem);
    width: 100%;
    max-width: calc(25% - 0.75rem);
  }
}
